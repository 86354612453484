<template>
  <section>
    <div>
      <b-overlay :show="invoicesLoading" spinner-medium>
        <b-card no-body class="mb-0">
          <app-data-table
            ref="refServiceListTable"
            :actions-table="{ showShow: true /* TODO RIGHT */ }"
            class="position-relative"
            :items="externalInvoices"
            responsive
            :fields="tableColumns"
            primary-key="quote.reference"
            :sort-by.sync="sortBy"
            show-empty
            :empty-text="$t('common.no_record_found')"
            @show="
              $router.push({
                name: 'OrderViewShow',
                params: { id: $event.item.orderId },
              })
            "
          >
            <!-- Column: Id -->
            <template #cell(reference)="data">
              <span>
                {{ data.item.quote.reference }}
              </span>
            </template>

            <template #cell(createdAt)="data">
              <span>
                {{ data.item.quote.createdAt | dateFormat }}
              </span>
            </template>

            <template #cell(totalOrderExclTaxes)="data">
              <span> {{ data.item.totalQuoteExcludingTaxes }} {{ $store.state.appConfig.currency }} </span>
            </template>

            <template #cell(totalOrderInclTaxes)="data">
              <span> {{ data.item.totalQuoteIncludingTaxes }} {{ $store.state.appConfig.currency }} </span>
            </template>

            <template #cell(invoiceNumber)="data">
              <span> {{ data.item.reference }} </span>
            </template>

            <template #cell(invoiceDate)="data">
              <span>
                {{ data.item.invoiceDate | dateFormat }}
              </span>
            </template>

            <template #cell(invoiceTotalExclTaxes)="data">
              <span> {{ data.item.totalInvoiceExcludingTaxes }} {{ $store.state.appConfig.currency }} </span>
            </template>

            <template #cell(invoiceTotalInclTaxes)="data">
              <span> {{ data.item.totalInvoiceIncludingTaxes }} {{ $store.state.appConfig.currency }} </span>
            </template>
          </app-data-table>
        </b-card>
        <!-- PAGINATION -->
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">{{ dataMeta.from }} {{ $t('pagination.entries_to') }} {{ dataMeta.to }} {{ $t('pagination.entries_of') }} {{ dataMeta.of }}
                {{ $tc('pagination.entries', dataMeta.of) }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="params.page"
                :total-rows="totalItems"
                :per-page="params.numberOfItemsPerPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
        <!-- PAGINATION -->
      </b-overlay>
    </div>
  </section>
</template>

<script>
import UIHelper from '@/helpers/ui'
import { fetchTripStepProviderExternalInvoicesRequest } from '@/request/globalApi/requests/tripStepEventRequests'
import AppDataTable from '@/components/AppDataTable.vue'

export default {
  name: 'TimelineStepProviderExternalInvoiceList',

  components: {
    AppDataTable,
  },
  mixins: [UIHelper],
  props: {
    stepEventId: {
      type: Number,
      default: null,
    },
    providerId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'reference', sortable: false, label: this.$t('common.reference') },
        { key: 'createdAt', sortable: false, label: this.$t('order.created_at') },
        { key: 'totalOrderExclTaxes', sortable: false, label: this.$t('timeline.provider.external_invoice.total_order_excl_taxes') },
        { key: 'totalOrderInclTaxes', sortable: false, label: this.$t('timeline.provider.external_invoice.total_order_incl_taxes') },
        { key: 'invoiceNumber', sortable: false, label: this.$t('timeline.provider.external_invoice.invoice_number') },
        { key: 'invoiceDate', sortable: false, label: this.$t('invoice.created_at') },
        { key: 'invoiceTotalExclTaxes', sortable: false, label: this.$t('timeline.provider.external_invoice.total_invoice_excl_taxes') },
        { key: 'invoiceTotalInclTaxes', sortable: false, label: this.$t('timeline.provider.external_invoice.total_invoice_incl_taxes') },
      ],
      params: {
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
      },
      perPageOptions: [5, 10, 25, 50, 100],
      sortBy: '',
      sortDesc: '',
      externalInvoices: [],
      totalItems: 0,
      invoicesLoading: false,
    }
  },
  computed: {
    userLang() {
      return this.$store.state.appConfig.lang
    },
    dataMeta() {
      const localItemsCount = this.externalInvoices.length ? this.externalInvoices.length : 0
      return {
        from: this.params.numberOfItemsPerPage * (this.params.page - 1) + (localItemsCount ? 1 : 0),
        to: this.params.numberOfItemsPerPage * (this.params.page - 1) + localItemsCount,
        of: this.totalItems,
      }
    },
    allFilters() {
      return {
        numberOfItemsPerPage: this.params.numberOfItemsPerPage,
        page: this.params.page,
        getAllItems: this.params.getAllItems,
      }
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
  },
  mounted() {
    this.APIFetchInvoices()
  },
  methods: {
    APIFetchInvoices() {
      this.invoicesLoading = true
      fetchTripStepProviderExternalInvoicesRequest(this.stepEventId, this.providerId)
        .then(response => {
          const { externalInvoices, totalItems } = response.data
          this.totalItems = totalItems
          this.externalInvoices = externalInvoices
        })
        .finally(() => {
          this.invoicesLoading = false
        })
    },
    refetchData() {
      this.APIFetchInvoices()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
.actionCol {
  width: 13%;
}
</style>
