<template>
  <section>
    <div>
      <b-overlay :show="invoicesLoading" spinner-medium>
        <b-card no-body class="mb-0">
          <app-data-table
            ref="refServiceListTable"
            :actions-table="{ showShow: true /* TODO RIGHT */ }"
            class="position-relative"
            :items="invoices"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            :empty-text="$t('common.no_record_found')"
            @show="
              $router.push({
                name: 'OrderViewShow',
                params: { id: $event.item.orderId },
              })
            "
          >
            <!-- Column: Id -->
            <template #cell(reference)="data">
              <span>
                {{ data.value }}
              </span>
            </template>

            <template #cell(createdAt)="data">
              <span>
                {{ data.value | dateFormat }}
              </span>
            </template>

            <template #cell(discountedPrice)="data">
              <span> {{ data.value }} {{ $store.state.appConfig.currency }} </span>
            </template>

            <template #cell(priceIncludingTaxes)="data">
              <span> {{ data.value }} {{ $store.state.appConfig.currency }} </span>
            </template>

            <template #cell(state)="data">
              <b-badge pill :variant="resolveInvoiceStateVariant(data.item.state)" class="text-capitalize">
                {{ data.item.state|enumTranslate('invoice_state') }}
              </b-badge>
            </template>
          </app-data-table>
        </b-card>
        <!-- PAGINATION -->
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">{{ dataMeta.from }} {{ $t('pagination.entries_to') }} {{ dataMeta.to }} {{ $t('pagination.entries_of') }} {{ dataMeta.of }}
                {{ $tc('pagination.entries', dataMeta.of) }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="params.page"
                :total-rows="totalItems"
                :per-page="params.numberOfItemsPerPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
        <!-- PAGINATION -->
      </b-overlay>
    </div>
  </section>
</template>

<script>
import UIHelper from '@/helpers/ui'
import { fetchTripStepProviderInvoicesRequest } from '@/request/globalApi/requests/tripStepEventRequests'
import AppDataTable from '@/components/AppDataTable.vue'

export default {
  name: 'TimelineStepProviderInvoiceList',

  components: {
    AppDataTable,
  },
  mixins: [UIHelper],
  props: {
    stepEventId: {
      type: Number,
      default: null,
    },
    providerId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'reference', label: this.$t('common.reference'), sortable: false },
        { key: 'createdAt', label: this.$t('invoice.created_at'), sortable: false },
        { key: 'discountedPrice', label: this.$t('pricing.total_price_before_tax'), sortable: false },
        { key: 'priceIncludingTaxes', label: this.$t('pricing.total_price_including_tax'), sortable: false },
        { key: 'state', label: this.$t('common.status'), sortable: false },
      ],
      params: {
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
      },
      perPageOptions: [5, 10, 25, 50, 100],
      sortBy: '',
      sortDesc: '',
      invoices: [],
      totalItems: 0,
      invoicesLoading: false,
    }
  },
  computed: {
    userLang() {
      return this.$store.state.appConfig.lang
    },
    dataMeta() {
      const localItemsCount = this.invoices.length ? this.invoices.length : 0
      return {
        from: this.params.numberOfItemsPerPage * (this.params.page - 1) + (localItemsCount ? 1 : 0),
        to: this.params.numberOfItemsPerPage * (this.params.page - 1) + localItemsCount,
        of: this.totalItems,
      }
    },
    allFilters() {
      return {
        numberOfItemsPerPage: this.params.numberOfItemsPerPage,
        page: this.params.page,
        getAllItems: this.params.getAllItems,
      }
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
  },
  mounted() {
    this.APIFetchInvoices()
  },
  methods: {
    APIFetchInvoices() {
      this.invoicesLoading = true
      fetchTripStepProviderInvoicesRequest(this.stepEventId, this.providerId)
        .then(response => {
          const { invoices, totalItems } = response.data
          this.totalItems = totalItems
          this.invoices = invoices
        })
        .finally(() => {
          this.invoicesLoading = false
        })
    },
    refetchData() {
      this.APIFetchInvoices()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
.actionCol {
  width: 13%;
}
</style>
