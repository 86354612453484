<template>
  <div>
    <!--HEADER-->
    <b-overlay spinner-medium :show="tripLoading">
      <b-row class="match-height">
        <b-col md="10">
          <trip-view :trip-data="trip" />
        </b-col>
        <b-col md="2">
          <trip-cost
            v-if="$can('TRIP_ACCOUNTING_VIEW')"
            :trip-total-cost-before-taxes="tripTotalCostBeforeTaxes"
            :trip-total-cost-including-taxes="tripTotalCostIncludingTaxes"
          />
        </b-col>
      </b-row>
      <!--TIMELINE -->
      <timeline-main
        :key="indexBuild"
        v-if="!tripLoading && !tripStepEventsLoading"
        :trip-data="trip"
        :trip-step-events="tripStepEvents"
        @refetch-data="refetchData($event)"
      />
    </b-overlay>
  </div>
</template>

<script>
import TripView from '@/views/trip/trip-view/TripView.vue'
import TripCost from '@/views/trip/TripCost.vue'
import { fetchTripInfosRequest, fetchTripOrganizationInfosRequest } from '@/request/globalApi/requests/tripRequest'
import { mapActions, mapState } from 'vuex'
import TimelineMain from '../timeline/TimelineMain.vue'
import TripLegCollapsible from "@/views/trip/leg/TripLegCollapsible.vue";

export default {
  name: 'TripDetail',

  components: {
    TripLegCollapsible,
    TripView,
    TripCost,
    TimelineMain,
  },
  data() {
    return {
      tripStepEventsLoading: true,
      tripStepEvents: [],
      tripTotalCostBeforeTaxes: null,
      tripTotalCostIncludingTaxes: null,
      tripDataLoading: true,
      indexBuild: 0,
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
    ...mapState('trip', ['trip', 'tripLoading']),
  },
  mounted() {
    this.fetchTripInfos()
  },
  watch: {
    trip(newVal, oldVal) {
      this.APIFetchTripOrganizationInfosRequest().finally(() => {
        this.indexBuild++
      })
    },
  },
  methods: {
    ...mapActions('trip', ['fetchTrip']),

    async fetchTripInfos() {
      const tripId = Number(this.$route.params.trip_id)
      const trip = await this.fetchTrip({ tripId, forced: true })

      if (trip.organization.id !== this.selectedOrganization.id) {
        await this.APIFetchTripOrganizationInfosRequest()
      } else {
        this.tripStepEventsLoading = false
      }

      this.APIFetchTripInfos()
    },
    APIFetchTripOrganizationInfosRequest() {
      return fetchTripOrganizationInfosRequest(this.trip.id)
        .then(response => {
          const { tripStepEvents } = response.data
          this.tripStepEvents[this.trip.id] = tripStepEvents
        }).finally(() => {
          this.tripStepEventsLoading = false
        })
    },
    APIFetchTripInfos() {
      fetchTripInfosRequest(this.trip.id)
        .then(response => {
          const { tripTotalCostBeforeTaxes, tripTotalCostIncludingTaxes } = response.data
          this.tripTotalCostBeforeTaxes = tripTotalCostBeforeTaxes
          this.tripTotalCostIncludingTaxes = tripTotalCostIncludingTaxes
        })
    },
    refetchData(forceReload = true) {
      if (forceReload) {
        window.location.reload()
      } else {
        this.fetchTripInfos()
      }
    },
  },
}
</script>
