<template>
  <b-card>
    <b-card-header class="d-flex justify-content-start pb-1 pt-0 px-0">
      <div class="h5">
        <span>{{ $t('trip.total_legs.estimated_cost') }}</span>
      </div>
    </b-card-header>
    <div>
      <span class="font-weight-bold">{{ $t('trip.total_legs.total_excluding_taxes') }}</span> :
      <span>{{ tripTotalCostBeforeTaxes | priceFormat }}</span>
    </div>
    <div>
      <span class="font-weight-bold">{{ $t('trip.total_legs.total') }}</span> :
      <span>{{ tripTotalCostIncludingTaxes | priceFormat }}</span>
    </div>
  </b-card>
</template>

<script>

import UIHelper from '@/helpers/ui'
import AccessControl from '@/helpers/accessControl'

export default {
  mixins: [UIHelper, AccessControl],
  props: {
    tripTotalCostBeforeTaxes: {
      type: Number,
      default: null,
    },
    tripTotalCostIncludingTaxes: {
      type: Number,
      default: null,
    },
  },
}
</script>
