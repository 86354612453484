export default {
  methods: {
    // Ville, Pays, Adresse
    // OU si aéroport Nom, ICAO
    tripStepEventName(tripStepEvent) {
      let tripStepEventName = `${tripStepEvent.address.city}, ${tripStepEvent.address.countryName}, ${tripStepEvent.address.address}`
      if (tripStepEvent.airport) {
        tripStepEventName = `${tripStepEvent.airport.name} - ${tripStepEvent.airport.icaoCode}`
      }

      return tripStepEventName
    },
    // Adresse, Code postal, Ville, Pays
    tripTransferEventName(tripStepEvent, depositZone = null) {
      let { address } = tripStepEvent
      if (depositZone) {
        address = depositZone
      }

      let tripStepEventName = address.address

      if (address.postalCode && address.postalCode !== '00000') {
        tripStepEventName = `${tripStepEventName}, ${address.postalCode}`
      }

      if (address.city && address.city !== address.address) {
        tripStepEventName = `${tripStepEventName}, ${address.city}`
      }

      if (address.countryName) {
        tripStepEventName = `${tripStepEventName}, ${address.countryName}`
      }

      return tripStepEventName
    },
    // Ville, Code postal
    tripTransferShortEventName(tripStepEvent, depositZone = null) {
      let { address } = tripStepEvent
      if (depositZone) {
        address = depositZone
      }

      let tripStepEventName = address.city

      if (address.postalCode && address.postalCode !== '00000') {
        tripStepEventName = `${tripStepEventName}, ${address.postalCode}`
      }

      return tripStepEventName
    },
    // Type Nom / Call sign - Registration
    tripAircraftName(aircraft) {
      let aircraftName = `${aircraft.type} ${aircraft.name}`

      if (aircraft.callSign) {
        aircraftName = `${aircraftName} / ${aircraft.callSign}`
      }

      if (aircraft.registration) {
        aircraftName = `${aircraftName} - ${aircraft.registration}`
      }

      return aircraftName
    },
  },
}
