<template>
  <section>
    <div>
      <b-overlay :show="quotesLoading" spinner-medium>
        <b-card class="mb-0" no-body>
          <b-table
            ref="refServiceListTable"
            :empty-text="$t('common.no_record_found')"
            :fields="tableColumns"
            :items="quotes"
            :sort-by.sync="sortBy"
            class="position-relative"
            primary-key="id"
            responsive
            show-empty
          >
            <!-- Column: Id -->
            <template #cell(reference)="data">
              <span>
                {{ data.value }}
              </span>
            </template>

            <template #cell(createdAt)="data">
              <span>
                {{ data.value | dateFormat }}
              </span>
            </template>

            <template #cell(priceExcludingTaxes)="data">
              <span> {{ data.value }} {{ $store.state.appConfig.currency }} </span>
            </template>

            <template #cell(priceIncludingTaxes)="data">
              <span> {{ data.value }} {{ $store.state.appConfig.currency }} </span>
            </template>

            <template #cell(state)="data">
              <b-badge v-if="data.item.cancelled" class="text-capitalize" pill variant="light-danger">
                {{ $t('state.cancelled') }}
              </b-badge>
              <b-badge v-else-if="data.item.refused" class="text-capitalize" pill variant="light-danger">
                {{ $t('state.refused') }}
              </b-badge>
              <b-badge v-else-if="data.item.cancelledDraft" class="text-capitalize" pill variant="light-danger">
                {{ $t('state.cancelled_draft') }}
              </b-badge>
              <b-badge v-else :variant="resolveQuoteStateVariant(data.item.state)" pill>
                {{ $t('enum.quote_state.' + data.item.state) }}
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <app-data-table-actions
                :show-delete="false"
                :show-duplicate="false"
                :show-edit="false"
                :show-show="true"
                display-mode="inline-icon"
                table-name="refServiceListTable"
                @show="
                  $router.push({
                    name: 'OrderViewShow',
                    params: { id: data.item.id },
                  })
                "
              />
            </template>
          </b-table>
        </b-card>
        <!-- PAGINATION -->
        <div class="mx-2 mb-2">
          <b-row>
            <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
              <span
                class="text-muted"
              >{{ dataMeta.from }} {{ $t('pagination.entries_to') }} {{ dataMeta.to }} {{ $t('pagination.entries_of') }}
                {{ dataMeta.of }}
                {{ $tc('pagination.entries', dataMeta.of) }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">
              <b-pagination
                v-model="params.page"
                :per-page="params.numberOfItemsPerPage"
                :total-rows="totalItems"
                class="mb-0 mt-1 mt-sm-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
        <!-- PAGINATION -->
      </b-overlay>
    </div>
  </section>
</template>

<script>
import UIHelper from '@/helpers/ui'
import { fetchTripStepProviderQuotesRequest } from '@/request/globalApi/requests/tripStepEventRequests'

import AppDataTableActions from '@/components/AppDataTableActions.vue'

export default {
  name: 'TimelineStepProviderQuotationList',

  components: {
    AppDataTableActions,
  },
  mixins: [UIHelper],
  props: {
    stepEventId: {
      type: Number,
      default: null,
    },
    providerId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      params: {
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
      },
      sortBy: '',
      quotes: [],
      totalItems: 0,
      quotesLoading: false,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'reference', label: this.$t('common.reference'), sortable: false },
        { key: 'createdAt', label: this.$t('order.created_at'), sortable: false },
        { key: 'priceExcludingTaxes', label: this.$t('pricing.total_price_before_tax'), sortable: false },
        { key: 'priceIncludingTaxes', label: this.$t('pricing.total_price_including_tax'), sortable: false },
        { key: 'state', label: this.$t('common.status'), sortable: false },
        {
          key: 'actions',
          label: this.$tc('action.title', 2),
          thClass: 'text-right',
          tdClass: 'text-center',
        },
      ]
    },
    dataMeta() {
      const localItemsCount = this.quotes.length ? this.quotes.length : 0
      return {
        from: this.params.numberOfItemsPerPage * (this.params.page - 1) + (localItemsCount ? 1 : 0),
        to: this.params.numberOfItemsPerPage * (this.params.page - 1) + localItemsCount,
        of: this.totalItems,
      }
    },
    allFilters() {
      return {
        numberOfItemsPerPage: this.params.numberOfItemsPerPage,
        page: this.params.page,
        getAllItems: this.params.getAllItems,
      }
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
  },
  activated() {
    this.APIFetchQuotes()
  },
  methods: {
    APIFetchQuotes() {
      this.quotesLoading = true
      fetchTripStepProviderQuotesRequest(this.stepEventId, this.providerId, this.params)
        .then(response => {
          const { quotes, totalItems } = response.data
          this.totalItems = totalItems
          this.quotes = quotes
        })
        .finally(() => {
          this.quotesLoading = false
        })
    },
    refetchData() {
      this.APIFetchQuotes()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.actionCol {
  width: 13%;
}
</style>
