<template>
  <div>
    <app-timeline-item
      v-for="(provider, providerIndex) in providerList"
      :key="provider.id + '' + providerIndex"
      :icon-awesome="resolveTimelineVariant('provider').icon"
      :variant="resolveTimelineVariant('provider').variant"
      class="timeline-provider"
    >
      <div v-if="selectedOrganization.id === tripData.organization.id || selectedOrganization.id === provider.organizationId">
        <!-- PROVIDER MAIN INFO -->
        <div class="d-flex align-items-start flex-sm-row flex-column flex-wrap mb-1 mb-sm-50 align-items-center">
          <b-media class="align-items-center">
            <template #aside>
              <b-avatar :src="provider.logoFileUrl ? provider.logoFileUrl : require('@/assets/images/logo/logo.png')" />
            </template>
            <div class="font-small-4 font-weight-bold">
              {{ provider.name }}
            </div>
          </b-media>
          <!--ACTION SERVICE LIST -->
          <b-button
            v-if="$can('TRIP_ACCOUNTING_VIEW')"
            v-model="providerDetails"
            v-b-toggle="'collapse_status-' + stepEvent.id + provider.id"
            v-ripple.400="'rgba(220, 193, 129, 0.15)'"
            :pressed="!!providerDetails"
            class="mx-1"
            size="sm"
            variant="light"
            @click.prevent="providerDetails = !providerDetails"
          >
            {{ providerDetails ? $t('action.hide') : $t('action.show') }}
            <font-awesome-icon :icon="providerDetails ? 'chevron-down' : 'chevron-up'" class="ml-50" size="sm" />
          </b-button>
          <!--ACTION SERVICE LIST -->
        </div>
        <!-- PROVIDER MAIN INFO -->

        <!--PROVIDER SERVICE DETAILS -->
        <b-collapse :id="'collapse_status-' + stepEvent.id + provider.id">
          <b-card class="border" no-body>
            <b-alert v-if="provider.type && provider.type === 'fuel' && provider.status === 'Pending'" class="m-0" show variant="primary">
              <div class="alert-body">
                <span>{{ $t('timeline.fuel.request_quotation.pending') }}</span>
              </div>
            </b-alert>

            <div v-else>
              <b-tabs lazy>
                <!--SERVICE LIST -->
                <b-tab :title="$tc('common.quoteLine', 1)">
                  <keep-alive>
                    <quote-lines-list :provider-id="provider.id" :step-event="stepEvent" :step-trip="stepTrip" />
                  </keep-alive>
                </b-tab>
                <!--SERVICE LIST -->

                <!--QUOTATIONS -->
                <b-tab :title="$t('common.quotation') | capitalize">
                  <b-row v-if="selectedOrganization.seller && $can('TRIP_ACCOUNTING_EDIT')">
                    <b-col class="d-flex align-items-center justify-content-end py-1 mb-1 mb-md-0" cols="12" md="12">
                      <b-button v-if="provider.type && provider.type === 'fuel'" v-b-toggle :href="'#sidebar-fuel-' + provider.id" @click.prevent>
                        {{ $t('timeline.fuel_certificate.add') }}
                      </b-button>
                      <b-button class="mx-1" @click="newQuote()">
                        {{ $t('timeline.provider.add_custom_quote') }}
                      </b-button>
                    </b-col>
                  </b-row>
                  <keep-alive>
                    <quotation-list :provider-id="provider.id" :step-event-id="stepEvent.id" />
                  </keep-alive>
                </b-tab>
                <!--QUOTATIONS -->

                <!--ORDERS-->
                <b-tab :title="$t('provider.orders') | capitalize">
                  <order-list :provider-id="provider.id" :step-event-id="stepEvent.id" />
                </b-tab>
                <!--ORDERS-->

                <!--INVOICES-->
                <b-tab :title="$t('common.invoice') | capitalize">
                  <!-- Suppression de l'édition facture depuis la timeline car non fonctionnel et ne rentre plus dans le process factu -->
                  <!-- <invoice-form v-if="$can('TRIP_ACCOUNTING_EDIT')" class="m-1" service-type="0" />-->
                  <invoice-list :provider-id="provider.id" :step-event-id="stepEvent.id" type-document="invoice" />
                </b-tab>
                <!--INVOICES-->

                <!--EXTERNAL INVOICES-->
                <b-tab :title="$t('common.external_invoice') | capitalize">
                  <external-invoice-list :provider-id="provider.id" :step-event-id="stepEvent.id" type-document="invoice" />
                </b-tab>
                <!--EXTERNAL INVOICES-->

                <!--COMMENT-->
                <b-tab v-if="false /* TODO */" :title="$t('common.comment')">
                  <b-alert class="m-1" show variant="primary">
                    <div class="alert-body">
                      <span>{{ $t('timeline.provider.no_comment_yet') }}</span>
                    </div>
                  </b-alert>
                </b-tab>
                <!--COMMENT-->
              </b-tabs>
            </div>
          </b-card>
        </b-collapse>
        <!--PROVIDER SERVICE LIST -->

        <!--SIDEBAR FUEL -->
        <sidebar-fuel-certificate v-if="provider.type && provider.type === 'fuel'" :step-provider="stepProvider" />
        <!--SIDEBAR FUEL -->
      </div>
    </app-timeline-item>
  </div>
</template>
<script>
import UIHelper from '@/helpers/ui'
import {
  fetchTripStepProvidersRequest,
  postTripStepEventCustomQuoteRequest,
} from '@/request/globalApi/requests/tripStepEventRequests'
import { mapState } from 'vuex'
import AccessControl from '@/helpers/accessControl'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import QuoteLinesList from './TimelineStepProviderQuoteLineList.vue'
import QuotationList from './TimelineStepProviderQuotationList.vue'
import InvoiceList from './TimelineStepProviderInvoiceList.vue'
import ExternalInvoiceList from './TimelineStepProviderExternalInvoiceList.vue'
import OrderList from './TimelineStepProviderOrderList.vue'
import InvoiceForm from '../../../invoice/InvoiceAdd.vue'
import SidebarFuelCertificate from '../../sidebar/SidebarFuelCertificate.vue'

export default {
  name: 'TimelineStepProvider',
  components: {
    AppTimelineItem,
    QuoteLinesList,
    QuotationList,
    OrderList,
    InvoiceForm,
    InvoiceList,
    ExternalInvoiceList,
    SidebarFuelCertificate,
  },
  mixins: [UIHelper, AccessControl],
  props: {
    stepProvider: {
      type: Object,
      default: () => ({}),
    },
    stepEvent: {
      type: Object,
      default: () => ({}),
    },
    stepTrip: {
      type: Object,
      default: () => ({}),
    },
    tripData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      providerList: [],
      providerDetails: false,
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
  },
  mounted() {
    this.getTripStepProviders()
  },
  methods: {
    getTripStepProviders() {
      fetchTripStepProvidersRequest(this.stepEvent.id).then(r => {
        if (
          this.selectedOrganization.id !== this.tripData.organization.id
            && !r.data.find(provider => provider.organizationId === this.selectedOrganization.id)
        ) {
          this.providerList = []
        } else {
          this.providerList = r.data
        }
      })
    },
    newQuote() {
      postTripStepEventCustomQuoteRequest(this.stepEvent).then(response => {
        this.$router.push({ name: 'OrderViewShow', params: { id: response.data.id, quoteEditable: true } })
      })
    },
  },
}
</script>
<style>
.timeline-provider {
  margin-left: -35px !important;
}
.timeline-provider:after {
  left: 24px !important;
}
</style>
