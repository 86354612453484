<template>
  <section>
    <b-card>
      <b-card-header class="d-flex align-items-baseline pb-1 pt-0 px-0">
        <!--NAME-->
        <div class="d-flex align-items-center">
          <span v-if="tripData.organization && tripData.organization.id === selectedOrganization.id" class="h3 mb-0 mr-1">{{ flightData.name }}</span>
          <span v-else-if="flightData.organization" class="h3 mb-0 mr-1">{{ flightData.organization.legalName }}</span>
          <span v-if="flightData.creator && flightData.creator.name">
            <span class="font-small-3">
              {{ $t('common.created_by') }}
            </span>
            <span>
              {{ flightData.creator.name }}
            </span>
          </span>
        </div>
        <!--ACTION BTN-->
        <div class="ml-auto">
          <b-button v-if="flightData && $can('TRIP_EDIT')" class="mr-1" variant="light" @click="switchEditFlight(flightData.id, 'update')">
            <font-awesome-icon class="mr-1" icon="edit" />
            {{ $t('action.edit') }}
          </b-button>

          <b-button v-if="$can('TRIP_DELETE')" v-b-modal.modal-prevent-closing class="mr-50" variant="danger">
            <font-awesome-icon class="mr-50" icon="ban" />
            {{ $t('action.cancel') }}
          </b-button>
          <!-- TODO -->
          <b-button v-if="false">
            <font-awesome-icon class="mr-50" icon="file-pdf" />
            {{ $t('action.export_pdf') }}
          </b-button>
          <slot name="trip-action" />
        </div>
      </b-card-header>
      <div class="mb-1">
        <div v-if="flightData.tripType === 'flight'">
          <span>
            <font-awesome-icon class="mr-50" icon="plane" />
            {{ flightData.organizationAircraft.type }} - {{ flightData.organizationAircraft.name }}

            <!-- Call sign - Registration -->
            <b-badge v-if="tripData.organizationAircraft.callSign || tripData.organizationAircraft.registration" variant="light-secondary" class="ml-2">
              <span v-if="flightData.organizationAircraft.callSign">{{ flightData.organizationAircraft.callSign }}</span>
              <span v-if="flightData.organizationAircraft.registration"> - {{ flightData.organizationAircraft.registration }}</span>
            </b-badge>
          </span>
        </div>
        <div v-else>
          <b-badge variant="light-secondary"> <font-awesome-icon class="mr-25" icon="helicopter" />{{ $t('trip.type.helicopter_only') }}</b-badge>
        </div>
      </div>
      <div v-if="flightData && flightData.tripSteps && flightData.tripSteps[0]" class="d-flex align-items-end">
        <b-link @click="goToLeg(flightData.tripSteps[0].id, flightData.id)">
          <b-breadcrumb v-for="(tripStep, index) in flightData.tripSteps" :key="index" class="p-0">
            <b-breadcrumb-item v-if="tripStep.className === 'TripFlight'">
              {{ tripStepEventName(tripStep.flightDeparture) }}
            </b-breadcrumb-item>
            <b-breadcrumb-item v-if="tripStep.className === 'TripFlight'">
              {{ tripStepEventName(tripStep.flightArrival) }}
            </b-breadcrumb-item>
            <b-breadcrumb-item v-if="tripStep.className === 'TripTransfer'">
              {{ tripTransferEventName(tripStep.transferDeparture, tripStep.departureDepositZone) }}
            </b-breadcrumb-item>
            <b-breadcrumb-item v-if="tripStep.className === 'TripTransfer'">
              {{ tripTransferEventName(tripStep.transferArrival, tripStep.arrivalDepositZone) }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </b-link>
        <!-- LIST LEGS -->
        <b-breadcrumb class="pl-0" />
      </div>
      <!-- DATE TRIP -->
      <b-badge v-if="flightData && flightData.tripSteps && flightData.tripSteps[0]" class="float-right ml-auto mr-50" variant="light-secondary">
        <font-awesome-icon class="mr-50" icon="calendar-alt" />
        <span>{{ displayDate(flightData.tripSteps[0]) }}</span>
      </b-badge>
    </b-card>
    <!--MODAL-->
    <b-modal
      id="modal-prevent-closing"
      ref="modal-delete"
      :ok-title="$t('action.delete')"
      :title="$t('alert.delete_confirmation_permanently.title')"
      cancel-variant="outline-secondary"
      @hidden="resetModal"
      @ok="handleOk"
      @show="resetModal"
    >
      <div class="py-1">
        <div>{{ $t('alert.delete_confirmation_permanently.message') }}</div>
        <div>{{ $t('alert.delete_confirmation_permanently.validate_deletion') }}</div>
      </div>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group :invalid-feedback="$t('alert.delete_confirmation_permanently.validate_deletion')" :state="deleteState">
          <b-form-input id="name-input" v-model="deleteInput" :state="deleteState" required />
        </b-form-group>
      </form>
    </b-modal>
  </section>
</template>

<script>
import { VBModal } from 'bootstrap-vue'
import { mapState } from 'vuex'

import AccessControl from '@/helpers/accessControl'
import UIHelper from '@/helpers/ui'
import { deleteTripRequest } from '@/request/globalApi/requests/tripRequest'
import TripHelper from '@/helpers/trip'

export default {
  name: 'TripViewFlight',

  directives: { 'b-modal': VBModal },
  mixins: [UIHelper, AccessControl, TripHelper],
  props: {
    tripData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      deleteInput: '',
      deleteState: null,
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
    flightData() {
      // If no props return trip from route trip id
      let currentTrip = null
      if (!this.tripData) {
        const tripId = this.$route.params.trip_id
        currentTrip = this.$store.getters['trip/currentTrip'](tripId)
      } else {
        currentTrip = this.tripData
      }
      return currentTrip
    },
  },
  methods: {
    displayDate(tripStep) {
      const departure = tripStep.flightDeparture ? 'flightDeparture' : 'transferDeparture'
      return `${this.$options.filters.dateFormat(tripStep[departure].eventDateTime)} (${this.$moment(tripStep[departure].eventDateTime).utc().format('HH:mm')}) ${this.$t('common.utc')}`
    },
    switchEditFlight() {
      this.$emit('update:flight-edit', true)
    },
    goToLeg(eventId, tripId) {
      if (this.$route.name === 'trip-view') {
        const el = document.getElementById(`event-${eventId}`)
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      } else {
        this.$router.push({ name: 'trip-view', params: { trip_id: tripId } })
      }
    },
    // MODAL CANCEL
    checkFormValidity() {
      this.deleteState = this.deleteInput.toLowerCase() === 'delete'
    },
    resetModal() {
      this.deleteInput = ''
      this.deleteState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.checkFormValidity()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.deleteState) return

      // Hide the modal manually
      this.$nextTick(() => {
        if (this.tripData) {
          deleteTripRequest(this.tripData.id, this.tripData.name).then(response => {
            response && this.$router.push({ name: 'trips' })
          })
          this.$refs['modal-delete'].toggle('#toggle-btn')
        }
      })
    },
  },
}
</script>
