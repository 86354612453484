<template>
  <b-sidebar
    id="sidebar-addPassenger"
    :visible="isAddNewPassengerSidebarActive"
    lasy
    no-header
    @change="val => $emit('update:is-add-new-passenger-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('crew.action.assign_crew') }}
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- BODY -->
      <b-overlay :show="crewLoading" no-fade>
        <validation-observer v-if="crewMembers.length" ref="addCrewOnStepSubmitRules" #default="{ handleSubmit }">
          <!--LIST-->
          <b-card class="border overflow-visible">
            <b-form ref="form" @submit.prevent="">
              <!-- Row Loop -->
              <b-row
                v-for="(crew, index) in localCrewMembers"
                :key="index"
                ref="rowLocation"
                class="d-flex align-items-center pt-50"
              >
                <b-col v-if="index" cols="12"><hr /></b-col>
                <b-col class="mb-sm-50" md="4">
                  <app-select
                    :id="`crew-member${index}`"
                    v-model="crew.crewMember"
                    :options="crewMembersFiltered"
                    label="fullName"
                    required
                    :clearable="false"
                    :label-field="$t('crew.title')"
                  />
                </b-col>
                <b-col class="mb-sm-50" md="5">
                  <enum-select
                    :id="`crew-position${index}`"
                    v-model="crew.crewPositionType"
                    :clearable="false"
                    :label="$t('crew.position')"
                    enum-class="CrewPositionType"
                    required
                  />
                </b-col>
                <b-col class="mt-25 d-flex justify-content-center" cols="">
                  <!-- Remove button -->
                  <b-button-group>
                    <b-button v-ripple.400 size="md" variant="light-primary" @click="confirmDeleteAlert(index)">
                      <font-awesome-icon v-b-tooltip.hover.bottom.v-danger :title="$t('action.delete')" class="text-primary" icon="minus" />
                    </b-button>
                    <b-button v-ripple.400 size="md" variant="light-primary" @click="repeatAgain()">
                      <font-awesome-icon v-b-tooltip.hover.bottom.v-danger :title="$t('action.add')" class="text-primary" icon="plus" />
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </validation-observer>
        <section v-if="crewMembers && !crewMembers.length" class="px-2 mb-1 mt-2">
          <b-alert class="m-0" show variant="primary">
            <div class="alert-body">
              {{ $t('alert.crew.no_crew_yet') }}
              <b-link v-if="$can('TRIP_EDIT')" :to="{ name: 'crews' }" class="alert-link">
                {{ $t('crew.action.add_crew_member') }}
              </b-link>
            </div>
          </b-alert>
        </section>
        <!--Footer-->
        <div class="d-flex pr-2 justify-content-end">
          <b-button v-if="crewMembers.length" class="mr-2" type="submit" @click.prevent="validationForm">
            <font-awesome-icon class="mr-50" icon="save" />
            {{ $t('action.save') }}
          </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="hide">
            <font-awesome-icon class="mr-50" icon="ban" />
            {{ $t('action.cancel') }}
          </b-button>
        </div>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { fetchCrewMembersRequest } from '@/request/globalApi/requests/crewMemberRequests'
import {
  editTripStepParticipantsRequest,
  fetchTripStepParticipantsRequest,
} from '@/request/globalApi/requests/tripStepParticipantRequests'
import AppSelect from '@/components/AppSelect.vue'

export default {
  name: 'SidebarAddCrew',

  components: { AppSelect },

  model: {
    prop: 'isAddNewPassengerSidebarActive',
    event: 'update:is-add-new-passenger-sidebar-active',
  },
  props: {
    isAddNewPassengerSidebarActive: {
      type: Boolean,
      required: true,
    },
    stepTrip: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      crewLoading: false,
      crewMembers: [],
      localCrewMembers: [],
      newCrewMembers: [],
      crewMembersSelected: {
        crewMemberId: null,
      },
    }
  },
  computed: {
    crewMembersFiltered() {
      const participantIdSelected = this.localCrewMembers.map(participant => participant.crewMember.id)
      return this.crewMembers.filter(participant => !participantIdSelected.includes(participant.id))
    },
  },
  mounted() {
    if (this.$can('CREW_VIEW')) {
      this.APIFetchCrewMembers()
    }
    this.APIFetchTripStepParticipant()
  },
  methods: {
    APIFetchTripStepParticipant() {
      this.crewLoading = true
      fetchTripStepParticipantsRequest(this.stepTrip.id)
        .then(r => {
          if (r.data.tripStepParticipants.length === 0) {
            this.localCrewMembers.push({ crewMember: { fullName: this.$t('crew.title') } })
          } else {
            this.localCrewMembers = r.data.tripStepParticipants
          }
        })
        .finally(() => {
          this.crewLoading = false
        })
    },
    APIFetchCrewMembers() {
      this.crewLoading = true
      fetchCrewMembersRequest()
        .then(response => {
          const { crewMembers } = response.data
          this.crewMembers = crewMembers
        })
        .finally(() => {
          this.crewLoading = false
        })
    },
    resetValidator() {
      requestAnimationFrame(() => {
        if (this.$refs.addCrewOnStepSubmitRules) {
          this.$refs.addCrewOnStepSubmitRules.reset()
        }
      })
    },
    validationForm() {
      this.$refs.addCrewOnStepSubmitRules.validate().then(success => {
        if (success) {
          this.onSubmit()
        }
      })
    },
    confirmDeleteAlert(index) {
      if (!this.localCrewMembers[index].id && this.localCrewMembers.length > 1) {
        this.localCrewMembers.splice(index, 1)
      } else {
        this.$bvModal
          .msgBoxConfirm(this.$t('alert.remove_confirmation.message'), {
            title: this.$t('alert.delete_confirmation.title'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('common.yes'),
            cancelTitle: this.$t('common.no'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(isConfirmed => {
            if (isConfirmed) {
              this.localCrewMembers.splice(index, 1)
              if (this.localCrewMembers.length === 0) { this.repeatAgain() }
            }
          })
      }
    },
    onSubmit() {
      this.crewLoading = true
      let crewNotAssigned = false

      this.localCrewMembers.forEach(localCrewMember => {
        if (localCrewMember.crewMember.fullName === this.$t('crew.title')) {
          crewNotAssigned = true
        }

        this.newCrewMembers.push({
          id: localCrewMember.crewMember.id,
          position: localCrewMember.crewPositionType,
        })
      })

      if (crewNotAssigned) {
        this.toaster(this.$t('alert.crew.not_assigned'), 'danger', 'AlertTriangleIcon')
        this.crewLoading = false
      } else {
        editTripStepParticipantsRequest(this.stepTrip.id, { crewMembers: this.newCrewMembers }).then(() => {
          this.resetValidator()
          this.APIFetchTripStepParticipant()
          this.crewLoading = false
        })
      }
    },
    repeatAgain() {
      if (this.crewMembersFiltered.length) {
        this.localCrewMembers.push({
          crewMember: {
            fullName: this.$t('crew.title'),
          },
        })
      }
    },
  },
}
</script>
