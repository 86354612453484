<template>
  <section v-if="tripData && tripData.tripSteps && !tripData.tripSteps.length">
    <b-alert class="m-0" show variant="primary">
      <div class="alert-body">
        <span>{{ $t('alert.trip.no_step_yet') }}</span>
        <b-link v-if="$can('TRIP_ADD')" :to="{ name: 'trip-view', params: { trip_id: tripData.id } }" class="alert-link">
          {{ $t('trip.action.add_step') }}
        </b-link>
      </div>
    </b-alert>
  </section>
  <section v-else>
    <v-select v-model="legSelected" :clearable="false" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="legOptions" />
  </section>
</template>

<script>
import TripHelper from '@/helpers/trip'

export default {
  name: 'TripLegSelector',

  mixins: [TripHelper],
  props: {
    tripData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      lastCollapse: '',
      legSelected: {
        label: this.$t('trip.event.hide_all'),
        value: 'hide',
        data: this.tripData.tripSteps,
        index: `${this.tripData.id}-hide`,
      },
    }
  },
  computed: {
    legOptions() {
      const options = [{
        label: this.$t('trip.event.hide_all'),
        value: 'hide',
        data: this.tripData.tripSteps,
        index: `${this.tripData.id}-hide`,
      }]

      if (this.tripData.tripSteps.length > 1) {
        options.push({
          label: this.$t('trip.all_legs'),
          value: 'all',
          data: this.tripData.tripSteps,
          index: `${this.tripData.id}-all`,
        })
      }

      this.tripData.tripSteps.forEach(tripStep => {
        if (tripStep.visibleInList) {
          let tripStepName = ''
          if (tripStep.className === 'TripFlight') {
            tripStepName = `${this.tripStepEventName(tripStep.flightDeparture)} > ${this.tripStepEventName(tripStep.flightArrival)}`
          } else {
            tripStepName = `${this.tripTransferEventName(tripStep.transferDeparture, tripStep.departureDepositZone)} > ${this.tripTransferEventName(tripStep.transferArrival, tripStep.arrivalDepositZone)}`
          }

          options.push({
            label: tripStepName,
            value: tripStep.id,
            data: [tripStep],
            index: `${this.tripData.id}-${tripStep.id}`,
          })
        }
      })
      return options
    },
  },
  watch: {
    legSelected(val) {
      this.toggleCollapses([val.index])
    },
  },
  methods: {
    toggleCollapses(ids) {
      this.$root.$emit('bv::toggle::collapse', this.lastCollapse)
      if (ids[0].includes('hide')) {
        this.lastCollapse = `hideTrip${this.tripData.id}`
      }
      if (ids[0].includes('all')) {
        this.$root.$emit('bv::toggle::collapse', `allTrip${this.tripData.id}`)
        this.lastCollapse = `allTrip${this.tripData.id}`
      } else {
        ids.forEach(id => {
          this.$root.$emit('bv::toggle::collapse', `collapse-trip_details${id}`)
          this.lastCollapse = `collapse-trip_details${id}`
        })
      }
    },
    onDeleteLeg() {
      // Restore select leg to all legs
      this.legSelected = {
        label: this.$t('trip.all_legs'),
        value: 'all',
        data: this.tripData.tripSteps,
        index: `${this.tripData.id}-all`,
      }
    },
  },
}
</script>
