<template>
  <div>
    <!-- TRIP STEP DETAILS SELECTOR -->
    <b-card v-if="tripData && tripData.tripSteps && tripData.tripSteps.length">
      <trip-leg-details
        :key="tripdataKey"
        :trip-data="tripData"
        :trip-step-events="tripStepEvents"
        @refetch-data="refetchData()"
      />
    </b-card>
    <!-- TRIP STEP DETAILS SELECTOR -->

    <b-card v-if="tripData && tripData.tripSteps">
      <!-- ACTION HIDE/SHOW PROVIDER -->
      <b-row class="pb-1">
        <b-col cols="12" class="d-flex justify-content-end">
          <!--TODO restore show all providers when APi connected-->
          <b-button
            v-if="false"
            v-b-toggle.collapse-providers
            variant="outline-primary"
            @click="showProviders = !showProviders"
          >
            <font-awesome-icon v-if="showProviders" icon="eye-slash" class="mr-50" />
            <font-awesome-icon v-else icon="eye" class="mr-50" />
            <span>{{ showProviders ? $t('timeline.providers.hide') : $t('timeline.providers.show') }}</span>
          </b-button>
        </b-col>
      </b-row>
      <!-- ACTION HIDE/SHOW PROVIDER -->

      <!-- ADD FIRST TRIP STEP EVENT -->
      <section v-if="tripData && !tripData.tripSteps.length">
        <b-row class="py-1">
          <b-col>
            <b-button v-ripple.400 size="lg" block @click="isAddNewEventSidebarActive = true">
              <font-awesome-icon icon="plus" />
              {{ $t('timeline.first_event.add') }}
            </b-button>
          </b-col>
        </b-row>
      </section>
      <!-- ADD FIRST TRIP STEP EVENT -->

      <!-- TIMELINE -->
      <section v-if="tripData && tripData.tripSteps.length">
        <app-timeline>
          <!-- ADD FIRST STEP -->
          <section>
            <app-timeline-item variant="primary" icon-awesome="flag" class="timeline-item_event p-2" />
            <section v-if="$can('TRIP_EDIT') && tripData.organization.id === selectedOrganization.id">
              <app-timeline-item variant="primary" icon-awesome="plus" class="mb-2 item_event_end">
                <b-button
                  v-ripple.400
                  class="m-0 p-0 mt-50"
                  variant="flat-primary"
                  @click="isAddNewEventSidebarActive = true"
                >
                  {{ $t('timeline.leg_transfer.add') }}
                </b-button>
              </app-timeline-item>
            </section>
          </section>
          <!-- ADD FIRST STEP -->

          <!-- ALL STEPS -->
          <section v-for="(event, index) in tripData.tripSteps" :key="index">
            <!--DEPARTURE-->
            <app-timeline-item
              :id="`event-${event.id}`"
              :class="['timeline-item_event', { 'timeline-item_event_end': tripData.tripSteps.length === index + 1 && !showProviders }]"
              :variant="resolveTimelineVariant('departure').variant"
              :icon-awesome="event.className === 'TripTransfer' ? resolveTimelineVariant('helicopter').icon : resolveTimelineVariant('departure').icon"
            >
              <section v-if="event.className !== 'TripTransfer'">
                <step-event-flight
                  :step-trip="event"
                  :step-event="event.flightDeparture"
                  :trip-data="tripData"
                  step-type="departure"
                  @refetch-data="$emit('refetch-data')"
                />
              </section>

              <section v-else>
                <!-- TODO prop required doesn't provide -->
                <step-event-transfer
                  class="pb-2"
                  :step-trip="event"
                  :trip-data="tripData"
                  :step-event="event"
                  @refetch-data="$emit('refetch-data')"
                />
              </section>
            </app-timeline-item>

            <app-timeline-item
              v-if="event.className === 'TripTransfer'"
              :id="`event-${event.id}`"
              :class="['timeline-item_event pb-2', { 'timeline-item_event_end': tripData.tripSteps.length === index + 1 && !showProviders }]"
              :variant="resolveTimelineVariant('arrival').variant"
              :icon-awesome="resolveTimelineVariant('helicopter').icon"
            >
              <section>
                <div class="d-flex justify-content-between align-items-center flex-sm-row flex-column flex-wrap mb-1 mb-sm-50 mr-1">
                  <!-- Data resume -->
                  <div class="d-flex align-items-center">
                    <!-- Title -->
                    <div class="font-small-4 font-weight-bold mr-1">
                      <span>{{ $t('trip.event.arrival') }} </span>
                      <span>{{ $t('common.to') }}  {{ tripTransferEventName(event.transferArrival, event.arrivalDepositZone) }}</span>
                    </div>

                    <!-- Event date time -->
                    <section v-if="event.transferArrival.eventDateTime && $moment(event.transferArrival.eventDateTime).format('ddd, MMM Do YYYY') !== 'Invalid date'" class="d-flex align-items-center">
                      <div>
                        <span class="font-small-3 mr-50">{{ $t('trip.event.eta') }} :</span>
                        <span class="mr-50">
                          <font-awesome-icon icon="calendar-alt" />
                          {{ $moment.utc(event.transferArrival.eventDateTime).format('ddd, MMM Do YYYY') }}
                        </span>
                        <span class="mr-50">
                          <font-awesome-icon icon="clock" />
                          {{ $moment.utc(event.transferArrival.eventDateTime).format('HH:mm') }}
                          {{ $t('common.utc') }}
                        </span>
                      </div>
                    </section>
                  </div>
                </div>
              </section>
            </app-timeline-item>

            <!--ARRIVAL-->
            <app-timeline-item
              v-if="event.className !== 'TripTransfer'"
              :id="`event-${event.id}`"
              :class="['timeline-item_event pb-2', { 'timeline-item_event_end': tripData.tripSteps.length === index + 1 && !showProviders }]"
              :variant="resolveTimelineVariant('arrival').variant"
              :icon-awesome="event.className === 'TripTransfer' ? resolveTimelineVariant('helicopter').icon : resolveTimelineVariant('arrival').icon"
            >
              <section v-if="event.className === 'TripFlight'">
                <step-event-flight
                  :step-trip="event"
                  :trip-data="tripData"
                  :step-event="event.flightArrival"
                  step-type="arrival"
                  @refetch-data="$emit('refetch-data')"
                />
              </section>
            </app-timeline-item>
          </section>

          <!--ADD TRIP STEP EVENT -->
          <section class="pt-1">
            <app-timeline-item
              v-if="$can('TRIP_EDIT') && tripData.organization.id === selectedOrganization.id"
              variant="primary"
              icon-awesome="plus"
              class="timeline-item_event pb-2 mt-2"
            >
              <b-button
                v-ripple.400
                class="m-0 p-0 mt-50"
                variant="flat-primary"
                @click="isAddNewEventSidebarActive = true"
              >
                {{ $t('timeline.leg_transfer.add') }}
              </b-button>
            </app-timeline-item>
          </section>

          <section>
            <app-timeline-item variant="primary" icon-awesome="flag-checkered" class="item_event_end p-2" />
          </section>
        </app-timeline>
      </section>
    </b-card>

    <!-- SIDEBAR ADD A NEW TRIP STEP EVENT -->
    <sidebar-add-event
      v-if="$can('TRIP_ADD') && tripData"
      :trip-data="tripData"
      :is-add-new-event-sidebar-active.sync="isAddNewEventSidebarActive"
      @refetch-data="refetchData($event)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import SidebarAddEvent from '@/views/timeline/sidebar/SidebarAddEvent.vue'
import StepEventFlight from '@/views/timeline/step/TimelineStepFlight.vue'
import StepEventTransfer from '@/views/timeline/step/TimelineStepTransfer.vue'
import UIHelper from '@/helpers/ui'
import AccessControl from '@/helpers/accessControl'
import TripLegDetails from '@/views/trip/leg/TripLegDetails.vue'
import TimelineStepProvider from '@/views/timeline/step/provider/TimelineStepProvider'
import TripHelper from '@/helpers/trip'

export default {
  name: 'TimelineMain',

  components: {
    TimelineStepProvider,
    TripLegDetails,
    AppTimeline,
    AppTimelineItem,
    StepEventTransfer,
    StepEventFlight,
    SidebarAddEvent,
  },
  mixins: [UIHelper, AccessControl, TripHelper],
  props: {
    tripData: {
      type: Object,
      default: () => ({}),
    },
    tripStepEvents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isAddNewEventSidebarActive: false,
      showProviders: false,
      tripSteps: [],
      tripdataKey: 0,
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
  },
  methods: {
    refetchData(forceReload = true) {
      this.$emit('refetch-data', forceReload)
    },
  },
}
</script>

<style scoped lang="scss">
#timeline_trip .timeline-item {
  padding-bottom: 13px;
}

.timeline-item_event:after {
  background: linear-gradient(transparent, transparent) !important;
  margin-bottom: -11px;
  border-right: 1px dotted #dcc181;
  width: 2px !important;
}

.timeline-item_event_end:after {
  background: transparent !important;
  border: none !important;
}

.timeline-item_last:after {
  background: linear-gradient(#ebe9f1, transparent) !important;
  border-right: 2px dotted #ebe9f1;
}

.timeline-item_last {
  padding-bottom: 55px;
}

.timeline-item:after {
  margin-left: -12px;
}

.timeline-item_last_end:after {
  background: transparent !important;
  border: none !important;
}

.timeline-item_last_end {
  padding-bottom: 0;
}
</style>
